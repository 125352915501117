import React from "react";
import * as styles from "./ServiceFeatureCard.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import classNames from "classnames";

export type ServiceFeatureCardProps = {
    title: string;
    description: string;
    image: JSX.Element;
    backgroundColor?: string;
    isReversed?: boolean;
    className?: string;
}

export function ServiceFeatureCard({
    title,
    description,
    image,
    backgroundColor,
    isReversed = false,
    className
}: ServiceFeatureCardProps) {
    return (
        <div 
            className={classNames(
                styles.container,
                { [styles.reversed]: isReversed },
                className
            )}
        >
            <div className={styles.content}>
                <TypographyV2
                    variant="HEADING_4"
                    color="brand-500"
                    className={styles.title}
                >
                    {title}
                </TypographyV2>
                <TypographyV2
                    variant="BODY_TEXT_EXTRA_LARGE"
                    color="text-tertiary-v2"
                    className={styles.description}
                >
                    {description}
                </TypographyV2>
            </div>
            <div 
                className={styles.imageContainer}
                style={{ backgroundColor }}
            >
                <div className={styles.imageWrapper}>
                    {image}
                </div>
            </div>
        </div>
    );
}

export default ServiceFeatureCard;