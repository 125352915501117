// extracted by mini-css-extract-plugin
export var bulletPoint = "ServiceFeatureIconGrid-module--bulletPoint--Gvp4E";
export var container = "ServiceFeatureIconGrid-module--container--D3klh";
export var featureContent = "ServiceFeatureIconGrid-module--featureContent--jo2Fi";
export var featureItem = "ServiceFeatureIconGrid-module--featureItem--hJFd9";
export var featureText = "ServiceFeatureIconGrid-module--featureText--ferN3";
export var featuresGrid = "ServiceFeatureIconGrid-module--featuresGrid--TCBUv";
export var iconWrapper = "ServiceFeatureIconGrid-module--iconWrapper--mv0XZ";
export var limitWidthOnLargeScreens = "ServiceFeatureIconGrid-module--limitWidthOnLargeScreens--5i4uF";
export var subtitleOverride = "ServiceFeatureIconGrid-module--subtitleOverride--hvbVD";
export var tooltipContent = "ServiceFeatureIconGrid-module--tooltipContent--k0BR-";
export var tooltipItem = "ServiceFeatureIconGrid-module--tooltipItem--C70nK";
export var underlined = "ServiceFeatureIconGrid-module--underlined--9rAMh";