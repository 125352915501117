import React from "react";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import * as styles from "./ServiceImprovement.module.scss";

export type ServiceHeroProps = {
    title: string;
    subtitle: string;
    image: JSX.Element;
    tabletImage: JSX.Element;
}

export default function ServiceHero ({
    title,
    subtitle,
    image,
    tabletImage,
}: ServiceHeroProps) {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.textContent}>
                    <TypographyV2 
                        variant="HEADING_2" 
                        color="brand-600"
                        className={styles.desktopTitle}
                    >
                        {title}
                    </TypographyV2>
                    <TypographyV2 
                        variant="BODY_TEXT_EXTRA_LARGE" 
                        color="text-tertiary-v2"
                        className={styles.desktopSubtitle}
                    >
                        {subtitle}
                    </TypographyV2>
                </div>
                <div className={styles.imageWrapper}>
                    <div className={styles.desktopImage}>
                        {image}
                    </div>
                    <div className={styles.tabletImage}>
                        {tabletImage}
                    </div>
                </div>
                <TypographyV2 
                    variant="BODY_TEXT_EXTRA_LARGE" 
                    color="text-tertiary-v2"
                    className={`${styles.textContent} ${styles.ltdesktopSubtitle}`}
                >
                    {subtitle}
                </TypographyV2>
            </div>
        </div>
    )
}