// extracted by mini-css-extract-plugin
export var container = "ServiceImprovement-module--container--O+ZoT";
export var content = "ServiceImprovement-module--content--Ft-zV";
export var desktopImage = "ServiceImprovement-module--desktopImage--7LaB3";
export var desktopSubtitle = "ServiceImprovement-module--desktopSubtitle--zU9uU";
export var desktopTitle = "ServiceImprovement-module--desktopTitle--ZHtCZ";
export var imageWrapper = "ServiceImprovement-module--imageWrapper--8hAnR";
export var limitWidthOnLargeScreens = "ServiceImprovement-module--limitWidthOnLargeScreens--m-5qj";
export var ltdesktopSubtitle = "ServiceImprovement-module--ltdesktopSubtitle--hmv1W";
export var tabletImage = "ServiceImprovement-module--tabletImage--aOy7q";
export var textContent = "ServiceImprovement-module--textContent--n3909";