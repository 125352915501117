import React from "react";
import { Typography } from "@components";
import SVG from "react-inlinesvg";
import * as styles from "./ServiceHighlights.module.scss";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";

export type ServiceItemProps = {
    icon: string;
    title: string;
}

export function ServiceItem({ icon, title }: ServiceItemProps) {
    return (
        <div className={styles.serviceItem}>
            <div className={styles.iconContainer}>
                <SVG src={icon} />
            </div>
            <Typography variant="SUBTITLE" color="brand-500" className={styles.itemTitle}>
                {title}
            </Typography>
        </div>
    );
}

export type ServiceHighlightsProps = {
    title?: string;
    subtext?: string;
    services: ServiceItemProps[];
}

export function ServiceHighlights({
    title,
    subtext,
    services
}: ServiceHighlightsProps) {
    return (
        <div className={styles.highlightsContainer}>
            {(title || subtext) && (
                <SectionTitle
                    title={title ? title : ""}
                    subtitle={
                        subtext? subtext : ""
                    }
                    textColor="text-tertiary"
                    subtitleWidth={1092}
                    marginTop={24}
                    marginBottom={0}
                    marginTopTablet={24}
                    marginBottomTablet={0}
                    marginTopMobile={4}
                    marginBottomMobile={14}
                />
            )}
            <div className={styles.servicesGrid}>
                {services.map((service, index) => (
                    <ServiceItem
                        key={`service-${index}`}
                        icon={service.icon}
                        title={service.title}
                    />
                ))}
            </div>
        </div>
    );
}

export default ServiceHighlights;