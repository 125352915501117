// extracted by mini-css-extract-plugin
export var buttonContainerOverride = "professional-services-module--buttonContainerOverride--ukr3z";
export var demoMargins = "professional-services-module--demoMargins--jvyDZ";
export var getADemoOverride = "professional-services-module--getADemoOverride--rV0rC";
export var heroImage = "professional-services-module--heroImage--qvHWj";
export var heroMargin = "professional-services-module--heroMargin--ldHx3";
export var leftSectionOverride = "professional-services-module--leftSectionOverride--7mAhK";
export var limitWidthOnLargeScreens = "professional-services-module--limitWidthOnLargeScreens--+U6VZ";
export var logosAndPerformerContainerStylesOverride = "professional-services-module--logosAndPerformerContainerStylesOverride--7lwk7";
export var rightSectionOverride = "professional-services-module--rightSectionOverride--rtJZQ";
export var rowContainerStylesOverride = "professional-services-module--rowContainerStylesOverride--VCKWI";
export var serviceFeatureCard = "professional-services-module--serviceFeatureCard--Q5zw1";
export var serviceFeatureCardMargins = "professional-services-module--serviceFeatureCardMargins--DC+UK";
export var subtitleOverride = "professional-services-module--subtitleOverride--4CJzW";
export var titleOverride = "professional-services-module--titleOverride--AxIQo";
export var trustedByContainerClass = "professional-services-module--trustedByContainerClass--w9Izi";
export var trustedByMargin = "professional-services-module--trustedByMargin--kcQ30";