import { PageLayout } from "@components";
import React from "react";
import { Demo } from "../solutions/biopharmaceutical";
import { Colors } from "@helpers/Colors";
import Margins from "@components/Margins/Margins";
import { ServiceHighlights } from "@components/ProfessionalServices/ServiceHighlights/ServiceHighlights";
import Installation from "./assets/Installation.svg";
import ContinuousImprovement from "./assets/ContinuousImprovement.svg";
import DataMigration from "./assets/DataMigration.svg";
import Support from "./assets/Support.svg";
import UserTrainingIcon from "./assets/UserTrainingIcon.svg";
import { StaticImage } from "gatsby-plugin-image";
import ServiceFeatureCard from "@components/ProfessionalServices/ServiceFeatureCard/ServiceFeatureCard";
import ServiceFeatureIconGrid from "@components/ProfessionalServices/ServiceFeatureIconGrid/ServiceFeatureIconGrid";
import TrustedByV2 from "@components/TrustedByV2/TrustedByV2";
import ServiceImprovement from "@components/ProfessionalServices/ServiceImprovement/ServiceImprovement";
import * as styles from "./professional-services.module.scss";

export default function ProfessionalServices() {
    const demoProps = {
        title: "Professional services",
        titleColor: "brand-500" as Colors,
        subtitle: "Enhance your research efficiency with precise solutions and support by Genemod specialists.",
        subTitleColor: "text-secondary" as Colors,
        srcImg: "professional-services-by-specialists.png",
        showSignUpFreeButton: false,
        disableBackgroundShapes: true,
        getADemoOverride: styles.getADemoOverride,
        leftSectionOverride: styles.leftSectionOverride,
        rightSectionStylesOverride: styles.rightSectionOverride,
        buttonContainerOverride: styles.buttonContainerOverride,
        titleStylesOverride: styles.titleOverride,
        subtitleStylesOverride: styles.subtitleOverride,
    };

    const servicesData = {
        subtext: "Genemod is designed to streamline your R&D workflows and data management for increased productivity. Our team is here to help you fully leverage our platform and achieve meaningful results.",
        services: [
            {
                icon: Support,
                title: "Professional support"
            },
            {
                icon: UserTrainingIcon,
                title: "User training"
            },
            {
                icon: DataMigration,
                title: "Data migration"
            },
            {
                icon: Installation,
                title: "Server installation"
            },
            {
                icon: ContinuousImprovement,
                title: "Continuous improvements"
            }
        ]
    };

    const features = [
        {
            title: "Professional support",
            description: "Our commitment extends beyond just providing a service. We work alongside you to understand and address your unique needs, offering ongoing, thorough assistance to ensure your team’s operations remain efficient, effective and well-supported. ",
            image: <StaticImage src="./assets/ProfessionalSupport.svg" alt="Professional Support" 
            objectFit="contain"
            style={{ width: "100%", height: "100%" }}
            />,
            backgroundColor: "var(--orange-25)"
        },
        {
            title: "User training",
            description: "We provide custom user training and onboarding services designed to help you fully leverage our platform within your lab. Through these sessions, you’ll gain a comprehensive understanding of our software, have any of your questions addressed, and be able to propose new features that could enhance your lab’s workflow.",
            image: <StaticImage src="./assets/UserTrainingSteps.svg" alt="User Training"
            objectFit="contain"
            style={{ width: "100%", height: "100%" }}/>,
            backgroundColor: "var(--lime-50)",
            isReversed: true
        },
        {
            title: "Data migration",
            description: "As part of our enterprise plan, we provide comprehensive data migration and custom integration services. Our team will assist in transferring your previous work to ensure it is thoroughly documented within the Genemod platform.",
            image: <StaticImage src="./assets/DataMigrationBanner.svg" alt="Data Migration" 
            objectFit="contain"
            style={{ width: "100%", height: "100%" }} />,
            backgroundColor: "var(--brand-50)",
            isReversed: false
        },
    ];

    const featuresIcons = [
        {
            title: "Custom deployment options",
            tooltip: [
                "On-premises deployment",
                "Private cloud deployment",
                "Public cloud deployment"
            ],
            underline: true
        },
        {
            title: "Custom authentication methods",
            tooltip: [
                "Sign Sign-On (SSO)",
                "Lightweight Directory Access Protocol (LDAP)",
                "Other authentication protocols"
            ],
            underline: true
        },
        {
            title: "Data  migration service",
            tooltip: [
                "Seamless data transfer and migration services"
            ],
            underline: true
        },
        {
            title: "Custom integration with hardware and software",
            tooltip: [
                "Integration with existing systems and third-party applications",
            ],
            underline: true
        },
        {
            title: "Custom automation solution",
            tooltip: [
                "Tailored automation solutions to streamline processes"
            ],
            underline: true
        },
        {
            title: "Advanced security and permission control",
            tooltip: [
                "Robust security features",
                "Granular permission settings and control"
            ],
            underline: true
        },
        
    ];

    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "professional-services" }}
        >
            <Margins className={styles.demoMargins}>
                <Demo {...demoProps} />
            </Margins>
            <ServiceHighlights {...servicesData} />
            <Margins className={styles.serviceFeatureCardMargins}>
                {features.map((feature, index) => (
                    <ServiceFeatureCard
                        key={`feature-${index}`}
                        {...feature}
                        className={styles.serviceFeatureCard}
                    />
                ))}
                <ServiceFeatureIconGrid
                title="Server installation"
                description="Our team ensures that the installation process is seamless and that all systems are thoroughly validated to meet your specific requirements. With our expert support, you can be confident that your Genemod platform will be set up for optimal performance and reliability."
                features={featuresIcons}
                />
            </Margins>
            <Margins className={styles.heroMargin}>
                <ServiceImprovement title="Continuous improvements" subtitle="Our team is dedicated to continuously refining and enhancing our platform to better meet your needs. Our engineers actively monitor and optimize system performance, incorporating user feedback and developing new features. This ongoing process ensures our solutions remain effective, enhancing your research and streamlining your workflows." image={<StaticImage src="./assets/ProfessionalServicesHero.png" alt="Professional Services Hero" className={styles.heroImage} />} tabletImage={<StaticImage src="./assets/ProfessionalServicesHeroTablet.png" alt="Professional Services Hero Tablet" className={styles.heroImage} />} />
            </Margins>               
            <Margins className={styles.trustedByMargin}>
                <TrustedByV2
                    trustedByContainerClass={styles.trustedByContainerClass}
                    logosContainerClass={styles.logosContainerClass}
                    logoTabletClass={styles.logoTabletClass}
                    logoClass={styles.logoClass}
                    badges={true}
                    rowContainerStylesOverride={styles.rowContainerStylesOverride}
                    logosAndPerformerContainerStylesOverride={styles.logosAndPerformerContainerStylesOverride}
                />
            </Margins>               
        </PageLayout>
    );
}