// ServiceFeatureIconGrid.tsx
import React, { useState } from "react";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import Tooltip from "rc-tooltip";
import classNames from "classnames";
import "rc-tooltip/assets/bootstrap.css";
import SVG from "react-inlinesvg";
import CHECKMARK from "@assets/icons/swoosh-check.svg";

import * as styles from "./ServiceFeatureIconGrid.module.scss";

type FeatureItemProps = {
    title: string;
    tooltip?: string[];
    underline?: boolean;
}

function FeatureItem({ title, tooltip, underline }: FeatureItemProps) {

    return (
        <Tooltip
            placement="right"
            align={{ offset: [0, 0] }}
            overlay={tooltip ? 
                <div className={styles.tooltipContent}>
                    {tooltip.map((item, index) => (
                        <div key={index} className={styles.tooltipItem}>
                            <span className={styles.bulletPoint}></span>
                            <span>{item}</span>
                        </div>
                    ))}
                </div> 
                : <></>
            }
            overlayInnerStyle={{
                borderRadius: "8px",
                padding: "0px",
                opacity: "1",
            }}
        >
            <div 
                className={styles.featureItem}
            >
                <div className={styles.iconWrapper}>
                    <SVG src={CHECKMARK} />
                </div>
                <div className={styles.featureContent}>
                    <span className={classNames(
                        styles.featureText,
                        { [styles.underlined]: underline }
                    )}>
                        {title}
                    </span>
                </div>
            </div>
        </Tooltip>
    );
}

export type IconFeatureGridProps = {
    title: string;
    description: string;
    features: FeatureItemProps[];
    className?: string;
}

export default function ServiceFeatureIconGrid({
    title,
    description,
    features,
    className
}: IconFeatureGridProps) {
    return (
        <div className={classNames(styles.container, className)}>
            <SectionTitle
                title={title}
                textColor="text-primary"
                subtitle={description}
                subtitleTextColor="text-primary-v2"
                titleWidth={843}
                subtitleWidth={1062}
                marginTop={20}
                marginBottom={40}
                marginTopTablet={0}
                marginBottomTablet={40}
                marginTopMobile={0}
                marginBottomMobile={40}
                subtitleOverride={styles.subtitleOverride}
            />
            <div className={styles.featuresGrid}>
                {features.map((feature, index) => (
                    <FeatureItem 
                        key={`feature-${index}`}
                        {...feature}
                    />
                ))}
            </div>
        </div>
    );
}